import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { ModalProvider } from './ModalContext';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { SettingsProvider } from './Telegram UI/SettingsContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
const MANIFEST_URL = `${window.location.origin}/tonconnect-manifest.json`;
root.render(
  <React.StrictMode>
     <BrowserRouter>
     
     <Provider store={store} >                              
      <TonConnectUIProvider manifestUrl={MANIFEST_URL}>
        <SettingsProvider>
        <App />
        </SettingsProvider>       
      </TonConnectUIProvider>
      </Provider>

     </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
